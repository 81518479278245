exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tools-average-price-js": () => import("./../../../src/pages/tools/average-price.js" /* webpackChunkName: "component---src-pages-tools-average-price-js" */),
  "component---src-pages-tools-dilution-effect-calculator-js": () => import("./../../../src/pages/tools/dilution-effect-calculator.js" /* webpackChunkName: "component---src-pages-tools-dilution-effect-calculator-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-profit-growth-js": () => import("./../../../src/pages/tools/profit-growth.js" /* webpackChunkName: "component---src-pages-tools-profit-growth-js" */),
  "component---src-templates-blog-list-page-js": () => import("./../../../src/templates/BlogListPage.js" /* webpackChunkName: "component---src-templates-blog-list-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-stock-post-js": () => import("./../../../src/templates/StockPost.js" /* webpackChunkName: "component---src-templates-stock-post-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/TagPage.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

